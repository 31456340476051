var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"position":"fixed","z-index":"2 !important","backdrop-filter":"blur(10px) brightness(0.9) contrast(0.9) saturate(0.65)","box-shadow":"rgb(0 0 0 / 46%) 0px 0px 52px !important","border-bottom":"1px solid rgb(75 75 75 / 42%) !important","max-width":"100vw"},attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"70"}},[(_vm.deploymentEnvironment.includes('stage.deliverdooh'))?_c('span',{staticClass:"text-button env-span"},[_c('v-icon',{staticStyle:{"color":"#22a4d1","font-size":"larger"}},[_vm._v("mdi-theater")]),_vm._v(" Stage Environment ")],1):(_vm.deploymentEnvironment.includes('localhost'))?_c('span',{staticClass:"text-button env-span"},[_c('v-icon',{staticStyle:{"color":"#ffaa00","font-size":"larger"}},[_vm._v("mdi-lan")]),_vm._v(" Local Environment ")],1):_vm._e(),_c('div',{staticClass:"nav-master-con"},[_c('div',{staticClass:"nav-left-con"},[_c('div',{staticClass:"nav-left-1"},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown
          ? _vm.setDrawer(!_vm.drawer)
          : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v("mdi-menu")]):_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"nav-left-2"},[_c('CampaignTabs')],1)]),_c('div',{staticClass:"nav-right-con"},[_c('div',{staticClass:"nav-right-1 d-flex"},[(_vm.$store.state.Permissions.todoBellNotifications)?_c('div',{staticStyle:{"padding-top":"8px"},attrs:{"id":"attachId"}},[_c('v-menu',{staticStyle:{"min-height":"300px"},attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"550","max-width":"550","close-on-select":false,"close-on-click":false,"close-on-content-click":false,"fixed":"","absolute":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',_vm._g({staticClass:"text-body",staticStyle:{"margin-right":"25px"},attrs:{"icon":"","small":""}},on),[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"22"}},[_vm._v(" notifications ")]),_c('v-badge',{staticClass:"position-absolute",attrs:{"color":"#f44335","content":_vm.redAlertCount,"offset-x":"1","offset-y":"0","bordered":""}})],1)]}}],null,true)})]}}],null,false,1885600149),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"pa-1",staticStyle:{"background-color":"all 0.3s ease-in-out"}},[_vm._l((_vm.notificationsHeaders),function(item){return _c('v-list-group',{key:item.type.name,style:({
            'max-width': '550px', 
            'max-height': '300px', 
            'overflow-y': 'auto', 
            'overflow-x': 'hidden', 
            'transition': 'all 0.3s ease-in-out',
            // Hide the scroll bar
            'scrollbar-width': 'none',
            '-ms-overflow-style': 'none',
          }),attrs:{"no-action":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(item.type.name + ' (' + item.notifications.length + ')'))])],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_vm._l((item.notifications),function(child,index){return _c('v-list-item',{key:index,staticClass:"pl-4 mb-10",on:{"click":function($event){_vm.openIssueTaskInCampaignStatus(_vm.getIssueIdHook(child.hooks, 'CampaignId'), _vm.getIssueIdHook(child.hooks, 'CampaignBurstId'), _vm.getIssueIdHook(child.hooks, 'IssueId'))}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticStyle:{"font-weight":"800"}},[_vm._v(_vm._s(index+1)+". "+_vm._s(child.title))]),_c('br'),_vm._v(" "+_vm._s(child.message))])],1)],1)}),(item.notifications.length === 0)?_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v("No notifications")])])],1):_vm._e()],2)}),_c('v-row',{staticStyle:{"max-width":"550px","max-height":"300px","display":"flex","justify-content":"end","padding-right":"10px"},attrs:{"no-gutters":""}},[(_vm.notificationLoader)?_c('v-progress-circular',{staticClass:"ml-0 mr-4",attrs:{"indeterminate":"","size":"28","color":"primary"}}):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"icon":"","color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){$event.stopPropagation();return _vm.getNotifications.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1682129379)},[_c('span',[_vm._v("Refresh")])])],1)],2)],1)],1):_vm._e(),_c('v-icon',{staticClass:"mouse-pointer mx-1",staticStyle:{"height":"20px","padding-top":"22px"},on:{"click":_vm.logoutUser}},[_vm._v(" mdi-logout ")])],1),_c('div',{ref:"signalrNav",staticClass:"nav-right-2"},[_c('SystemVersion'),_c('div',{key:"nav2",ref:"signalrNavInner",staticStyle:{"position":"relative"}},[_c('ConnectionStatus',{staticStyle:{"position":"absolute","top":"0","right":"7px"}}),_c('ActiveDirectory',{staticStyle:{"width":"fit-content","overflow":"visible"},attrs:{"navmaxitems":_vm.navMaxItems}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }